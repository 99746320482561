import { ActivatedRoute } from '@angular/router';
import { ToastOptions } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { RolesEnum } from '../models/enum/roles.enum';
import { MonthList } from '../models/interfaces/month-list.interface';
import { UserProxy } from '../models/proxies/user.proxy';

export async function delay(milliseconds: number): Promise<void> {
  return await new Promise((resolve) => setTimeout(resolve, milliseconds));
}

export function setZeroAtLeft(num: number): string {
  return num <= 9 ? `0${num}` : `${num}`;
}

export function noRef<T>(mock: T): T {
  if (['string', 'number'].includes(typeof mock)) return mock;

  return JSON.parse(JSON.stringify(mock));
}

export function isAdminUser(user?: UserProxy): boolean | string | undefined {
  return user && user.roles && hasRole(user.roles, RolesEnum.ADMIN);
}

export function isNormalUser(user?: UserProxy): boolean | string | undefined {
  return (
    user &&
    user.roles &&
    hasRole(user.roles, RolesEnum.USER) &&
    !isAdminUser(user)
  );
}

export function hasRole(roles: string[], targetRole: string): boolean {
  return !!roles && roles.some((role) => role === targetRole);
}

export function getErrorMessage({ status, error, message }: any): string {
  if (status === 503)
    return (
      error?.message ||
      'Ocorreu um erro inesperado, por favor, experimente atualizar a página e tentar novamente.'
    );

  if (status >= 500 && status <= 599)
    return 'Ocorreu um erro inesperado, por favor, experimente atualizar a página e tentar novamente.';

  if (!error) {
    if (Array.isArray(message)) return message[0];

    return (
      message ||
      'Ocorreu um erro inesperado, por favor, experimente atualizar a página e tentar novamente.'
    );
  }

  if (!Array.isArray(error.message)) {
    if (typeof error.message === 'string' && error.message.includes('Cannot'))
      return 'A rota especificada não foi encontrada, por favor, contate os administradores se o erro persistir.';

    return (
      error.message ||
      'Ocorreu um erro inesperado, por favor, experimente atualizar a página e tentar novamente.'
    );
  }

  if (error.message.every((message: any) => typeof message === 'string'))
    return error.message[0];

  // @ts-ignore
  return error.message
    .map(({ constraints }: any) => (constraints && Object.values(constraints)) || [])
    .reduce((acc: any, actual: any) => [...acc, ...actual] as string[])[0];
}

export function isString(value: any): boolean {
  return Object.prototype.toString.call(value) === '[object String]';
}

export function getCurrentUser(): UserProxy | null {
  try {
    return JSON.parse(String(localStorage.getItem(environment.keys.user)));
  } catch (e) {
    return null;
  }
}

export function convertSecondsToHour(timeInSeconds: number): string {
  const hours = Math.floor(timeInSeconds / (60 * 60));
  let minutes = Math.floor(timeInSeconds / 60);
  let slackMinutes = Math.floor(minutes / 60);
  let restHours = timeInSeconds % (60 * 60);

  minutes = minutes - 60 * slackMinutes;
  restHours %= 60;
  const seconds = Math.ceil(restHours);

  return `${hours}:${minutes}:${seconds}`;
}

export function convertHourToSeconds(time: string): number {
  var currentTime = time.split(':'); // split it at the colons

  return +currentTime[0] * 60 * 60 + +currentTime[1] * 60 + +currentTime[2];
}

export function getFirstLetterOfFirstAndLastNames(userName: string): string[] {
  userName = userName.trim();
  const nameFirstLetter = userName[0].toUpperCase();

  const splitName = userName.split(' ');

  let lastnameFirstLetter = splitName[splitName.length - 1].toUpperCase();
  lastnameFirstLetter = [...lastnameFirstLetter][0];

  return [nameFirstLetter, lastnameFirstLetter];
}

export function createErrorToastOptions(message: string = '', cssClasses: string[] = []): ToastOptions {
  return {
    header: 'Ops...',
    message: message,
    position: 'top',
    cssClass: ['my-toast', ...cssClasses],
    duration: 5000,
    color: 'danger',
    buttons: [
      {
        text: 'X',
        role: 'cancel',
      }
    ]
  }
}

export function createSuccessToastOptions(message: string = '', cssClasses: string[] = []): ToastOptions {
  return {
    header: 'Sucesso!',
    message: message,
    position: 'top',
    cssClass: ['my-toast', ...cssClasses],
    duration: 5000,
    color: 'success',
    buttons: [
      {
        text: 'X',
        role: 'cancel',
      }
    ]
  }
}

export function setMonthName(month: number): string {
  let monthName: string = '';

  switch (month) {
    case 0:
      monthName = 'JAN';
      break;
    case 1:
      monthName = 'FEV';
      break;
    case 2:
      monthName = 'MAR';
      break;
    case 3:
      monthName = 'ABR';
      break;
    case 4:
      monthName = 'MAI';
      break;
    case 5:
      monthName = 'JUN';
      break;
    case 6:
      monthName = 'JUL';
      break;
    case 7:
      monthName = 'AGO';
      break;
    case 8:
      monthName = 'SET';
      break;
    case 9:
      monthName = 'OUT';
      break;
    case 10:
      monthName = 'NOV';
      break;
    case 11:
      monthName = 'DEZ';
      break;
  }

  return monthName;
}

export function setMonthList(): MonthList[] {
  return [
    {
      name: 'JAN',
      status: '',
    },
    {
      name: 'FEV',
      status: '',
    },
    {
      name: 'MAR',
      status: '',
    },
    {
      name: 'ABR',
      status: '',
    },
    {
      name: 'MAI',
      status: '',
    },
    {
      name: 'JUN',
      status: '',
    },
    {
      name: 'JUL',
      status: '',
    },
    {
      name: 'AGO',
      status: '',
    },
    {
      name: 'SET',
      status: '',
    },
    {
      name: 'OUT',
      status: '',
    },
    {
      name: 'NOV',
      status: '',
    },
    {
      name: 'DEZ',
      status: '',
    }
  ];
}