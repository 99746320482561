//#region Imports

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { LoginPayload } from '../../models/payloads/login.payload';
import { CreateUserPayload } from '../../models/payloads/register.payload';
import { TokenProxy } from '../../models/proxies/token.proxy';
import { UserProxy } from '../../models/proxies/user.proxy';
import { AsyncResult } from '../../modules/http-async/models/async-result';
import { HttpAsyncService } from '../../modules/http-async/services/http-async.service';
import { InstanceService } from '../../services/instance/instance.service';

//#endregion

@Injectable({
  providedIn: 'root',
})
export class AuthInteractor {

  //#region Constructor

  constructor(
    private readonly http: HttpAsyncService,
    private readonly instanceService: InstanceService,
  ) { }

  //#endregion

  //#region Auth Methods

  public async performOrganizationLogin(payload: LoginPayload): Promise<AsyncResult<TokenProxy>> {
    return await this.http.post<TokenProxy>(environment.routes.auth, payload);
  }

  public async createUser(payload: CreateUserPayload): Promise<AsyncResult<UserProxy>> {
    return await this.http.post<UserProxy>('/users', { ...payload, lang: environment.config.lang });
  }

  public async getMe(): Promise<AsyncResult<UserProxy>> {
    return await this.http.get<UserProxy>(environment.routes.userMe);
  }

  public async updateMe(userId: number, payload: Partial<UserProxy>): Promise<AsyncResult<UserProxy>> {
    return await this.http.put(environment.api.users.update.replace('{userId}', userId.toString()), payload);
  }

  public isEmailUsed$(email: string): Observable<boolean> {
    const url = environment.api.users.isEmailUsed.replace('{email}', email);

    return this.http.getHttpClient()
      .get<{ isEmailUsed: boolean }>(url)
      .pipe(
        catchError(() => of({ isEmailUsed: false })),
        map(payload => payload.isEmailUsed),
      );
  }

  //#endregion

}
