//#region Imports

import { Injectable } from '@angular/core';

import * as Sentry from '@sentry/browser';
import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { AuthInteractor } from '../../interactors/auth/auth.interactor';
import { LoginPayload } from '../../models/payloads/login.payload';
import { CreateUserPayload } from '../../models/payloads/register.payload';
import { UserProxy } from '../../models/proxies/user.proxy';
import { getErrorMessage } from '../../utils/functions';
import { StorageService } from '../storage/storage.service';
import { ToastController, ToastOptions } from '@ionic/angular';
import { InstanceService } from '../instance/instance.service';

//#endregion

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  //#region Constructor

  constructor(
    private readonly interactor: AuthInteractor,
    private readonly storage: StorageService,
    private readonly toastController: ToastController,
  ) { }

  //#endregion

  //#region Events

  private readonly onChangeAuthentication: BehaviorSubject<UserProxy | undefined> = new BehaviorSubject<UserProxy | undefined>(void 0);

  private successToastOptions: ToastOptions = {
    header: 'Sucesso!',
    message: 'Você deslogou da sua conta com sucesso.',
    position: 'top',
    duration: 5000,
    color: 'success',
    buttons: [
      {
        text: 'X',
        role: 'cancel',
      }
    ]
  }

  //#endregion

  //#region Auth Methods

  public async setupAuthentication(): Promise<void> {
    const { error, success } = await this.storage.getItem<UserProxy>(environment.keys.user);

    if (error || !success)
      return;

    Sentry.setUser({ id: success.id?.toString(), email: success.email, username: success.name });

    if (this.onChangeAuthentication.getValue()?.id === success.id)
      return;

    this.onChangeAuthentication.next(success);
  }

  public isAuthenticated(): boolean {
    return !!this.onChangeAuthentication.getValue();
  }

  public async logout(shouldShowToast: boolean = true): Promise<void> {
    await this.storage.clear();

    this.onChangeAuthentication.next(void 0);

    if (shouldShowToast)
      (await this.toastController.create(this.successToastOptions)).present();
  }

  public async performLogin(payload: LoginPayload): Promise<[boolean, string?]> {
    Sentry.setUser({
      username: payload.username,
      email: payload.username,
      roles: ['user'],
      domain: payload.domain,
    });

    const { error, success: token } = await this.interactor.performOrganizationLogin(payload);

    if (error)
      return [false, getErrorMessage(error)];

    await this.storage.setItem(environment.keys.token, token);
    return [true];
  }

  public async getMe(): Promise<UserProxy | undefined> {
    const { error, success } = await this.interactor.getMe();

    if (error)
      return;

    await this.storage.setItem<UserProxy>(environment.keys.user, success);

    this.onChangeAuthentication.next(success);

    return success;
  }

  public async updateMe(payload: Partial<UserProxy>): Promise<[boolean, string?]> {
    const user = this.getCurrentUser();

    if (!user)
      return [false, 'Você não está logado na plataforma, logo, não é possível atualizar as suas informações.'];

    const { error, success } = await this.interactor.updateMe(user.id, payload);

    if (error)
      return [false, getErrorMessage(error)];

    await this.storage.setItem<UserProxy>(environment.keys.user, success);

    this.onChangeAuthentication.next(success);

    return [true];
  }

  public async registerUser(payload: CreateUserPayload): Promise<[boolean, string?]> {
    Sentry.setUser({
      username: `${payload.name}`,
      email: payload.email,
      roles: payload.roles,
      domain: payload.domain,
    });

    payload.email = payload.email.toLowerCase();

    const { error } = await this.interactor.createUser({
      ...payload,
      name: `${payload.name}`,
    });

    if (error)
      return [false, getErrorMessage(error)];

    const isSuccess = await this.performLogin({ 
      password: payload.password, 
      username: payload.email, 
      roles: payload.roles,
      domain: payload.domain,
    });

    if (!isSuccess)
      return [false, 'Seu usuário foi criado mas não foi possível autenticar automaticamente, por favor, vá em login e entre.'];

    await this.getMe();

    return [true];
  }

  public isEmailUsed$(email: string): Observable<boolean> {
    return this.interactor.isEmailUsed$(email);
  }

  //#endregion

  //#region User Methods

  public getCurrentUser(): UserProxy {
    return this.onChangeAuthentication.getValue() as UserProxy;
  }

  public getCurrentUser$(): Observable<UserProxy | undefined> {
    return this.onChangeAuthentication.asObservable();
  }

  //#endregion

}
