//#region Imports

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';

//#endregion

@Injectable({
  providedIn: 'root',
})
export class SetupAuthenticationGuard implements CanActivate {

  //#region Constructor

  constructor(
    private readonly auth: AuthService,
  ) { }

  //#endregion

  //#region public methods

  public async canActivate(__: ActivatedRouteSnapshot, _: RouterStateSnapshot) {
    await this.auth.setupAuthentication();

    return true;
  }

  //#endregion
}
