//#region Imports

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { InstanceService } from 'src/app/services/instance/instance.service';

import { environment } from '../../../environments/environment';
import { TokenProxy } from '../../models/proxies/token.proxy';
import { StorageService } from '../../services/storage/storage.service';

//#endregion

@Injectable({
  providedIn: 'root',
})
export class AuthenticateGuard implements CanActivate {

  //#region Constructor

  constructor(
    private readonly router: Router,
    private readonly storage: StorageService,
    private readonly instanceService: InstanceService,
  ) { }

  //#endregion

  //#region public methods

  public async canActivate(route: ActivatedRouteSnapshot, _: RouterStateSnapshot): Promise<any> {
    const { unprotectedRoute, protectedRoute, routeToRedirect } = route.data || {};

    if (!routeToRedirect)
      return true;

    const hasToken = await this.storage.getItem<TokenProxy>(environment.keys.token).then(result => !!result.success);


    if (hasToken && protectedRoute) 
      return true;

    if (!hasToken && unprotectedRoute)
      return true;

    return void await this.router.navigate([routeToRedirect], {
      replaceUrl: true,
      queryParams: {
        backUrl: `/main/${route.url.map(r => r.toString()).join('/')}`,
      },
      queryParamsHandling: 'merge',
    });
  }

  //#endregion

}
