import { versionName } from "./version";

export const environment = {
    production: false,
    qa: true,
    apiBaseUrl: 'https://api-qas.clube-afiliados.ligafacens.com',
    version: versionName,
    build: 'b5',
    COMPANY_ID: 0,
    config: {
        imgproxyUrl: 'https://us-east-1.imgproxy.ligafacens.com/',
        redirectToWhenAuthenticated: '/main/inicio',
        redirectToWhenUnauthenticated: '/login',
        lang: 'pt-BR',
    },
    routes: {
        auth: '/auth/local',
        refreshToken: '/auth/refresh',
        userMe: '/users/me',
    },
    api: {
        users: {
            listPaginated: '/users?s={searchParams}&page={page}&limit={limit}',
            get: '/users/{userId}',
            update: '/users/{userId}',
            isEmailUsed: '/users/emails/{email}/used',
            verifyCode: '/users/verification/{code}',
        },
        userProducts: {
            get: '/users-products?join=product&join=product.company&join=product.principalTag&s={searchParams}',
            create: '/users-products',
            delete: '/users-products/{userProductId}',
        },
        products: {
            listPaginated: '/products?s={searchParams}&page={page}&limit={limit}',
            listPaginatedWithJoins: '/products?join=company&join=principalTag&join=category&s={searchParams}&page={page}&limit={limit}',
            get: '/products/{productId}?join=category&join=company&join=principalTag',
            update: '/products/{productId}',
            bestSales: '/products/best-sales?month={month}&limit={limit}&page={page}',
        },
        productsRatings: {
            getWithParams: '/products-ratings?s={searchParams}',
            create: '/products-ratings',
            update: '/products-ratings/{productRatingId}',
        },
        tags: {
            listPaginated: '/tags?page={page}&limit={limit}',
            get: '/tags/{tagId}',
            update: '/tags/{tagId}',
        },
        leads: {
            create: '/leads?companyId={companyId}',
            list: '/leads',
            values: '/leads/values',
        },
        pages: {
            getWithSearchParams: '/pages?s={searchParams}'
        },
        categories: {
            listPaginated: '/categories?page={page}&limit={limit}',
            listPaginatedWithParams: '/categories?s={searchParams}&page={page}&limit={limit}',
            get: '/categories/{categoryId}',
            update: '/categories/{categoryId}',
        },
        banners: {
            listPaginated: '/banners?page={page}&limit={limit}',
            get: '/banners/{userId}',
        },
        usersPassword: {
            forgotPasswordEmail: '/users-password/forgot/email/{email}/domain/{domain}',
            forgotPasswordCode: '/users-password/isValidResetPasswordCode',
            resetPassword: '/users-password/reset/{resetPasswordCode}',
            updateUserPassword: '/users-password/password/change/domain/{domain}',
        },
        companies: {
            listPaginated: '/companies?page={page}&limit={limit}',
            get: '/companies/{companyId}',
        },
        userProductSales: {
            get: '/users-products-sales/{userProductSaleId}',
            update: '/users-products-sales/{userProductSaleId}',
            getWithSearchParams: '/users-products-sales?s={searchParams}'
        },
        divulgationPages: {
            listWithParams: '/divulgations-pages?s={searchParams}&join=user&join=sectionOne&join=sectionTwo&join=sectionThree&join=sectionFour',
            get: '/divulgations-pages/{divulgationPageId}?join=user&join=sectionOne&join=sectionTwo&join=sectionThree&join=sectionFour',
            create: '/divulgations-pages',
            update: '/divulgations-pages/{divulgationPageId}',
        },
    },
    keys: {
        token: 'USER_TOKEN',
        user: 'USER_INFO',
        isRegister: 'IS_REGISTER',
        forceSubdomain: '@afiliates/force-subdomain',
    },
    sentry: {
        dns: 'https://9f1c86b2b6f34be9b760ac5b49a1c768@sentry.ligafacens.com/91',
        server: 'https://sentry.ligafacens.com/api',
        release: '1.0.0',
        tags: {
            framework: 'angular',
            provider: 'liga',
            type: 'web',
            name: 'liga.web.angular.ncursos',
        },
        feedback: {
            lang: 'pt-BR',
            title: 'Parece que estamos tendo alguns problemas.',
            subtitle: 'Nossa equipe foi notificada.',
            subtitle2: 'Se você quiser ajudar, conte-nos o que aconteceu abaixo.',
            labelName: 'Nome',
            labelEmail: 'E-mail',
            labelComments: 'O que aconteceu?',
            labelSubmit: 'Enviar',
            labelClose: 'Fechar',
            errorGeneric: 'Ocorreu um erro desconhecido ao enviar o seu feedback. Por favor, tente novamente.',
            errorFormEntry: 'Alguns campos são inválidos. Corrija os erros e tente novamente.',
            successMessage: 'Seu feedback foi enviado. Obrigado!',
        },
    },
}
