/* #region  Imports */

import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';

/* #endregion */

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  //#region Constructor

  constructor(
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.window = this.document.defaultView;
  }

  //#endregion

  //#region public properties

  public favIcon!: HTMLLinkElement | null;

  //#endregion

  //#region private properties

  private darkIcon: string = '/assets/imgs/bro_favicon_dark.svg';

  private window: Window | null;

  //#endregion

  //#region LifeCycle Events

  public async ngOnInit(): Promise<void> {
    this.favIcon = document.querySelector('#appIcon');

    if (this.window!.matchMedia && this.window!.matchMedia('(prefers-color-scheme: dark)').matches)
      this.favIcon!.href = this.darkIcon;
  }

  //#endregion

}
