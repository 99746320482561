//#region  Imports

import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

import * as Sentry from '@sentry/browser';

//#endregion

@Injectable({
  providedIn: 'root',
})
export class StorageService {

  //#region  Constructor

  constructor(
    private readonly storage: Storage,
  ) {
    this.storage.create();
  }

  //#endregion

  //#region Public Methods

  public async setItem<T>(key: string, item: T | undefined): Promise<StorageAsyncResult<T>> {
    const onSuccess: () => StorageAsyncResult<T> = () => ({ success: item });

    return this.storage.set(key, item)
      .then(onSuccess)
      .catch(e => this.onError(e));
  }

  public async getItem<T>(key: string): Promise<StorageAsyncResult<T>> {
    const onSuccess: (item: T) => StorageAsyncResult<T> = (item: T) => ({ success: item });

    return this.storage.get(key)
      .then(onSuccess)
      .catch(e => this.onError(e));
  }

  public async remove(key: string): Promise<StorageAsyncResult<boolean>> {
    const onSuccess: () => StorageAsyncResult<boolean> = () => ({ success: true });

    return this.storage.remove(key)
      .then(onSuccess)
      .catch(e => this.onError(e));
  }

  public async clear(): Promise<StorageAsyncResult<boolean>> {
    const onSuccess: () => StorageAsyncResult<boolean> = () => ({ success: true });

    return this.storage.clear()
      .then(onSuccess)
      .catch(e => this.onError(e));
  }

  //#endregion

  //#region Private Methods

  private onError<T>(error?: Error): StorageAsyncResult<T> {
    Sentry.captureException(error || new Error('Ocorreu um erro, verifique se há espaço no seu celular para salvar as informações necessárias.'));

    if (error && error.message)
      console.error(error && error.message);

    return <StorageAsyncResult<T>> { error: { message: 'Ocorreu um erro, verifique se há espaço no seu celular para salvar as informações necessárias.' } };
  }

  //#endregion

}

//#region Interfaces

export interface StorageAsyncResult<T> {

  success?: T;

  error?: { message: string };

}

//#endregion
