//#region Imports

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import decode from 'jwt-decode';
import { from, BehaviorSubject, Observable } from 'rxjs';
import { catchError, concatAll, filter, map, take } from 'rxjs/operators';

import { AuthService } from '../../../services/auth/auth.service';
import { StorageService } from '../../../services/storage/storage.service';
import { TokenProxy } from '../../../models/proxies/token.proxy';
import { environment } from '../../../../environments/environment';
import { ToastController } from '@ionic/angular';
import { createErrorToastOptions } from 'src/app/utils/functions';

//#endregion

/*necessário implementar um toast pra qndo não ocorrer o refresh do token*/
@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {

  //#region Constructor

  constructor(
    private readonly router: Router,
    private readonly auth: AuthService,
    private readonly storage: StorageService,
    private readonly toastController: ToastController,
  ) { }

  //#endregion

  //#region Variables

  private readonly refreshState$ = new BehaviorSubject<{ refreshing: boolean, token?: TokenProxy }>({ refreshing: false });

  private sessionDidExpire: boolean = false;

  //#region Methods

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.canPerformRequest()).pipe(
      map(canPerform => {
        if (!canPerform)
          throw new HttpErrorResponse({ error: { message: 'A sua sessão expirou, você precisa logar novamente.' }, status: 401 });

        this.sessionDidExpire = false;

        return next.handle(req);
      }),
      concatAll(),
      catchError(error => {
        if (error.status !== 401)
          throw error;

        if (req.url.includes('/auth/local'))
          throw error;
        
        this.auth.logout()
          .then(async () => {
            if (this.sessionDidExpire)
              return;

            this.sessionDidExpire = true;
            
            const toastr = this.toastController.create(createErrorToastOptions('A sua sessão expirou, você precisa logar novamente.'));

            (await toastr).present();

            await this.router.navigateByUrl('/login', { replaceUrl: true });
          });

        throw error;
      }),
    );
  }

  //#endregion

  //#region Private Methods

  private async tryRefreshToken(refreshToken: string): Promise<TokenProxy | undefined> {
    if (this.refreshState$.value.refreshing) {
      // Espera a atualização que está ocorrendo no mesmo momento
      const state = await this.refreshState$.pipe(filter(x => !x.refreshing)).pipe(take(1)).toPromise();

      if (state?.token)
        return state.token;
    }

    this.refreshState$.next({ refreshing: true });

    const proxy: TokenProxy | undefined = await fetch(
      environment.apiBaseUrl + environment.routes.refreshToken,
      {
        method: 'POST',
        headers: {
          Authorization: refreshToken,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      },
    )
      .then(async result => result.ok ? await result.json() : undefined)
      .catch(() => undefined);

    this.refreshState$.next({ refreshing: false, token: proxy });

    return proxy;
  }

  private isTokenExpired(token: string, maxExpiresDate: number): boolean {
    const jwtPayload: { exp: number } = decode(token);

    return maxExpiresDate >= +new Date(jwtPayload.exp * 1000);
  }

  private async canPerformRequest(): Promise<boolean> {
    const { success: token } = await this.storage.getItem<TokenProxy>(environment.keys.token);

    // Se não temos um token, continuaremos a requisição mesmo assim
    if (!token || !token.token)
      return true;

    const fiveSecondsInMilliseconds = 1_000 * 5;
    const maxSafeExpiresDate = +new Date() + fiveSecondsInMilliseconds;

    // Se o token não está expirado, continua a solicitação
    if (!this.isTokenExpired(token.token, maxSafeExpiresDate))
      return true;

    // Se o refresh token está expirado, teremos que forçar o usuário a relogar
    if (!token.refreshToken || this.isTokenExpired(token.refreshToken, maxSafeExpiresDate))
      return false;

    const proxy = await this.tryRefreshToken(token.refreshToken);

    if (!proxy)
      return false;

    await this.storage.setItem(environment.keys.token, proxy);

    return true;
  }

  //#endregion

}

//#endregion
