/* #region  Imports */

import { NgModule } from '@angular/core';
import { PreloadAllModules, Route, RouterModule, Routes } from '@angular/router';
import { environment } from '../environments/environment';
import { AuthenticateGuard } from './guards/authenticate/authenticate.guard';
import { SetupAuthenticationGuard } from './guards/authenticate/setup-authentication.guard';

/* #endregion */

export const unAuthenticatedRoute: Partial<Route> = { canActivate: [SetupAuthenticationGuard, AuthenticateGuard], data: { routeToRedirect: environment.config.redirectToWhenAuthenticated, unprotectedRoute: true } };

export const authenticatedRoute: Partial<Route> = { canActivate: [SetupAuthenticationGuard, AuthenticateGuard], data: { routeToRedirect: environment.config.redirectToWhenUnauthenticated, protectedRoute: true } };

const routes: Routes = [
  { path: '', redirectTo: 'main/inicio', pathMatch: 'full' },
  { path: 'main', loadChildren: () => import('./pages/main/main.module').then(m => m.MainModule) },
  { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule), ...unAuthenticatedRoute },
  { path: '**', redirectTo: 'main/inicio' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      paramsInheritanceStrategy: 'always',
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
