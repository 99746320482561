//#region imports

import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { InstanceProxy } from '../../models/proxies/instance.proxy';
import { HttpAsyncService } from '../../modules/http-async/services/http-async.service';

//#endregion

@Injectable({
  providedIn: 'root'
})
export class InstanceService {

  //#region constructor

  constructor(
    protected readonly router: Router,
    protected readonly http: HttpAsyncService,
    protected readonly title: Title,
  ) { }

  //#endregion

  //#region Public Properties

  public currentInstance?: InstanceProxy;

  //#endregion

  //#region Public Methods

  public async initializeInstance(): Promise<void> {
    const subdomain = this.getCurrentSubdomain();

    await this.loadInstanceBySubdomain(subdomain);

    (window as any).__afiliatedGetInstance = () => this.currentInstance;

    (window as any).__afiliatedSetCurrentInstanceBySubdomain = (domain: any) => this.loadInstanceBySubdomain(domain);

    (window as any).__afiliatedClearForcedSubdomain = () => localStorage.removeItem(environment.keys.forceSubdomain);

    (window as any).__afiliatedForceSubdomain = (domain: any) => {
      localStorage.setItem(environment.keys.forceSubdomain, domain);

      this.loadInstanceBySubdomain(domain);
    };
  }

  public getCurrentSubdomain(): string {
    const overrideSubdomain = localStorage.getItem(environment.keys.forceSubdomain);
    
    if (overrideSubdomain)
      return  overrideSubdomain;

    let url = location.hostname.split('.');

    if (!environment.production && !environment.qa)
      return 'facens';

    if (environment.qa)
      url = url[0].split('-')

    return url[0] || 'localhost';
  }

  //#endregion

  //#region Private Methods

  private async loadInstanceBySubdomain(subdomain: string): Promise<void> {
    if (!environment.production && !environment.qa)
      subdomain = 'facens';
      
    const { error, success } = await this.http.get<number>(`/companies/domain/${subdomain}`);

    if (!success || error)
      return void await this.router.navigateByUrl('/instance-not-found');

    this.setupInstance(success);
  }

  private setupInstance(instance: number): void {
    environment.COMPANY_ID = instance;
  }

  //#endregion

}
