//#region Imports

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import fileSize from 'filesize';

import { Observable, Subject, firstValueFrom } from 'rxjs';
import { BaseUrlInterceptor } from '../interceptors/base-url.interceptor';
import { BearerTokenInterceptor } from '../interceptors/bearer-token.interceptor';
import { HttpAsyncHeadersInterceptor } from '../interceptors/http-async-headers.interceptor';

import { AsyncResult } from '../models/async-result';
import { DefaultOptions, ExtraOptions } from '../models/http-options';

//#endregion

@Injectable()
export class HttpAsyncService {

  //#region Construtor

  constructor(
    protected readonly http: HttpClient,
  ) { }

  //#endregion

  //#region Private Properties

  private readonly onHttpError: Subject<HttpErrorResponse> = new Subject<HttpErrorResponse>();

  //#endregion

  //#region Public Methods

  public getOnHttpError$(): Observable<HttpErrorResponse> {
    return this.onHttpError.asObservable();
  }

  public getHttpClient(): HttpClient {
    return this.http;
  }

  //#endregion

  //#region Private Methods

  private success<T>(result: T): AsyncResult<T> {
    return {
      success: result,
    };
  }

  private error<T>(error: HttpErrorResponse): AsyncResult<T> {
    this.onHttpError.next(error);

    return {
      error,
    };
  }

  //#endregion

  //#region Async Restfull Methods

  public async get<T>(
    url: string,
    options?: DefaultOptions,
  ): Promise<AsyncResult<T>> {
    return await firstValueFrom(await this.http.get<T>(url, options))
      .then((data: T) => {
        return this.success(data);
      })
      .catch((error: HttpErrorResponse) => {
        return this.error<T>(error);
      })
      .then<AsyncResult<T>>((result: AsyncResult<T>) => {
        return result;
      });
  }

  public async post<T>(
    url: string,
    payload: object,
    options?: ExtraOptions,
  ): Promise<AsyncResult<T>> {
    return await firstValueFrom(await this.http.post<T>(url, payload, options))
      .then((data: T) => {
        return this.success(data);
      })
      .catch((error: HttpErrorResponse) => {
        return this.error<T>(error);
      })
      .then<AsyncResult<T>>((result: AsyncResult<T>) => {
        return result;
      });
  }

  public async put<T>(
    url: string,
    payload: object,
    options?: ExtraOptions,
  ): Promise<AsyncResult<T>> {
    return await firstValueFrom(await this.http.put<T>(url, payload, options))
      .then((data: T) => {
        return this.success(data);
      })
      .catch((error: HttpErrorResponse) => {
        return this.error<T>(error);
      })
      .then<AsyncResult<T>>((result: AsyncResult<T>) => {
        return result;
      });
  }

  public async delete<T>(
    url: string,
    options?: ExtraOptions,
  ): Promise<AsyncResult<T>> {
    return await firstValueFrom(await this.http.delete<T>(url, options))
      .then((data: T) => {
        return this.success(data);
      })
      .catch((error: HttpErrorResponse) => {
        return this.error<T>(error);
      })
      .then<AsyncResult<T>>((result: AsyncResult<T>) => {
        return result;
      });
  }


  public async getFileSize(url: string): Promise<AsyncResult<string>> {
    return await firstValueFrom(await this.http.get(url, {
      headers: {
        Range: 'bytes=0-0',
        [BearerTokenInterceptor.DISABLE_HEADER]: 'true',
        [BaseUrlInterceptor.DISABLE_HEADER]: 'true',
        [HttpAsyncHeadersInterceptor.DISABLE_HEADER]: 'true',
      },
      responseType: 'arraybuffer',
      observe: 'response',
    }))
      .then((data) => {
        const size = Number(data?.headers.get('content-range')?.split('/')[1]);

        return this.success(fileSize(size));
      })
      .catch((error: HttpErrorResponse) => {
        return this.error(error);
      })
      .then<AsyncResult<any>>((result: AsyncResult<any>) => {
        return result;
      });
  }

  //#endregion

}


