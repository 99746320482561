import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IonicModule } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BaseUrlInterceptor } from './modules/http-async/interceptors/base-url.interceptor';
import { HttpAsyncHeadersInterceptor } from './modules/http-async/interceptors/http-async-headers.interceptor';
import { BearerTokenInterceptor } from './modules/http-async/interceptors/bearer-token.interceptor';
import { RefreshTokenInterceptor } from './modules/http-async/interceptors/refresh-token-interceptor.service';
import { RetryInterceptor } from './modules/http-async/interceptors/retry.interceptor';
import { HttpAsyncModule } from './modules/http-async/http-async.module';
import { environment } from 'src/environments/environment';
import { InstanceService } from './services/instance/instance.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { getPortuguesePaginatorIntl } from './utils/shared/portuguese-paginator';
import { MatPaginatorIntl } from '@angular/material/paginator';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpAsyncModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot({
      dbKey: '__clubeAfiliados',
    }),
    HttpClientModule,
    HttpAsyncModule.withConfig({
      baseUrl: environment.apiBaseUrl,
      defaultHeaders: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      bearerTokenKey: environment.keys.token,
    }),
    BrowserAnimationsModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: BaseUrlInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpAsyncHeadersInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: BearerTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RefreshTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RetryInterceptor, multi: true },
    { provide: MatPaginatorIntl, useValue: getPortuguesePaginatorIntl() },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [InstanceService],
      useFactory: (instance: InstanceService) => () => instance.initializeInstance(),
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
