//#region Imports

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { from, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { TokenProxy } from '../../../models/proxies/token.proxy';
import { StorageService } from '../../../services/storage/storage.service';
import { HttpAsyncConfig } from '../models/http-async.config';
import { HTTP_ASYNC_CONFIG } from '../models/injection-tokens';

//#endregion

@Injectable()
export class BearerTokenInterceptor implements HttpInterceptor {

  //#region Constructor

  constructor(
    protected readonly storage: StorageService,
    @Inject(HTTP_ASYNC_CONFIG)
    @Optional()
    protected readonly config?: HttpAsyncConfig,
  ) { }

  //#endregion

  //#region Public Static Properties

  public static readonly DISABLE_HEADER: string = 'X-Disabled-BearerToken';

  //#endregion

  //#region Public Methods

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this.config?.bearerTokenKey) {
      console.warn('Você incluiu o Interceptor para adicionar o Bearer Token a requisição mas não configurou a chave para buscar o valor do cache no módulo.');

      return next.handle(req);
    }

    if (!req.headers.get(BearerTokenInterceptor.DISABLE_HEADER)) {
      return from(this.storage.getItem<TokenProxy>(this.config.bearerTokenKey))
        .pipe(
          mergeMap((result: any) => {
            if (!result.success)
              return next.handle(req);

            const headers = req.headers.set('Authorization', result.success.token);

            req = req.clone({
              headers,
            });

            return next.handle(req);
          }),
        );
    }

    req = req.clone({
      headers: req.headers.delete(BearerTokenInterceptor.DISABLE_HEADER),
    });

    return next.handle(req);
  }

  //#endregion

}
